import React from "react";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Services from "./components/Services";
import Gallery from "./components/Gallery";
import Pricing from "./components/Pricing";
import Review from "./components/Review";
import Contact from "./components/Contact";
import "./App.css"
import Footer from './components/Footer';

const App = () => {

  return (
    <>
      <Navbar />
      <Home />
      <Services />
      <Gallery />
      <Pricing />
      <Review />
      <Contact />
      <Footer />
    </>
  );
};

export default App;
