import React from 'react';

const Contact = () => {
  return (
    <section className="contact" id="contact">
      <h1 className="heading">
        <span>Kontakiere</span> uns
      </h1>

      <form action="">
        <div className="inputBox">
          <input type="text" placeholder="Name" />
          <input type="email" placeholder="Email" />
        </div>
        <div className="inputBox">
          <input type="number" placeholder="Nummer" />
          <input type="text" placeholder="Betreff" />
        </div>
        <textarea name="" placeholder="Ihre Nachricht..." id="" cols="30" rows="10"></textarea>
        <input type="submit" value="Senden" className="btn" />
      </form>
    </section>
  );
};

export default Contact;
