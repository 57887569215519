import React from "react";

const Services = () => {
  return (
    <section class="services" id="services">
      <h1 class="heading">
        {" "}
        unser <span>service</span>{" "}
      </h1>

      <div class="box-container">
        <div class="box">
          <span>01</span>
          <i class="fas fa-code"></i>
          <h3>web design</h3>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magni,
            iste.
          </p>
        </div>

        <div class="box">
          <span>02</span>
          <i class="fas fa-mobile"></i>
          <h3>responsive</h3>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magni,
            iste.
          </p>
        </div>

        <div class="box">
          <span>03</span>
          <i class="fas fa-palette"></i>
          <h3>custom design</h3>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magni,
            iste.
          </p>
        </div>

        <div class="box">
          <span>04</span>
          <i class="fas fa-bullhorn"></i>
          <h3>seo marketing</h3>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magni,
            iste.
          </p>
        </div>

        <div class="box">
          <span>05</span>
          <i class="fas fa-envelope"></i>
          <h3>email marketing</h3>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magni,
            iste.
          </p>
        </div>

        <div class="box">
          <span>06</span>
          <i class="fas fa-headset"></i>
          <h3>24/7 service</h3>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magni,
            iste.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Services;
