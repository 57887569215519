import React from "react";

const Footer = () => {

  const year = new Date().getFullYear();

  return (
    <section class="footer">
      <div class="box-container">
        <div class="box">
          <h3>links</h3>
          <a href="#">
            {" "}
            <i class="fas fa-chevron-right"></i> home{" "}
          </a>
          <a href="#">
            {" "}
            <i class="fas fa-chevron-right"></i> service{" "}
          </a>
          <a href="#">
            {" "}
            <i class="fas fa-chevron-right"></i> galerie{" "}
          </a>
          <a href="#">
            {" "}
            <i class="fas fa-chevron-right"></i> preise{" "}
          </a>
          <a href="#">
            {" "}
            <i class="fas fa-chevron-right"></i> bewertungen{" "}
          </a>
          <a href="#">
            {" "}
            <i class="fas fa-chevron-right"></i> kontakt{" "}
          </a>
        </div>

        <div class="box">
          <h3>kontakt info</h3>
          <a href="https://q-tech.dev" target="_blank" rel="noreferrer">
            {" "}
            <i class="fas fa-globe"></i> Q-Tech Dev{" "}
          </a>
          <a href="mailto:info@q-tech.dev">
            {" "}
            <i class="fas fa-envelope"></i> Info@Q-Tech.dev{" "}
          </a>
          <a href="tel: +4915736453216">
            {" "}
            <i class="fas fa-phone"></i> +49 1573 645 32 16{" "}
          </a>
          <a href="https://goo.gl/maps/ZvqB165MgGNwWFud9" target="_blank" rel="noreferrer">
            {" "}
            <i class="fas fa-map-marker-alt"></i> Esslingen am Neckar{" "}
          </a>
        </div>

        <div class="box">
          <h3>folge uns</h3>
          <a href="#">
            {" "}
            <i class="fab fa-facebook-f"></i> facebook{" "}
          </a>
          <a href="#">
            {" "}
            <i class="fab fa-twitter"></i> twitter{" "}
          </a>
          <a href="#">
            {" "}
            <i class="fab fa-instagram"></i> instagram{" "}
          </a>
          <a href="#">
            {" "}
            <i class="fab fa-linkedin"></i> linkedin{" "}
          </a>
          <a href="#">
            {" "}
            <i class="fab fa-pinterest"></i> pinterest{" "}
          </a>
        </div>

        <div class="box">
          <h3>folge uns</h3>
          <a href="#">
            {" "}
            <i class="fab fa-windows"></i> windows{" "}
          </a>
          <a href="#">
            {" "}
            <i class="fab fa-apple"></i> app store{" "}
          </a>
          <a href="#">
            {" "}
            <i class="fab fa-google-play"></i> play store{" "}
          </a>
        </div>
      </div>

      <div class="credit">
        {" "}
        &copy; copyright {year} Q-Tech Dev - Show3. Design by 
             <a href="http://www.Q-tech.dev" rel="nofollow"><span> 
             Qualified Technologies Development</span>{" "}</a>
      </div>
    </section>
  );
};

export default Footer;
