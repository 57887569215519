import React from "react";

const Home = () => {
  return (
    <section class="home" id="home">
      <div class="content">
        <h3>Ihr inhalt könnte hier stehen</h3>
        <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus
          asperiores numquam harum autem consectetur et quas! Eveniet impedit
          dolores quod.
        </p>
        <a href="#services" class="btn">
          Erkunden
        </a>
        <a href="https://q-tech.dev/beispiele" class="btn">
          Q-Tech Dev
        </a>
      </div>
    </section>
  );
};

export default Home;
