import React from "react";
import G1 from './../images/g-1.jpg'
import G2 from './../images/g-2.jpg'
import G3 from './../images/g-3.jpg'
import G4 from './../images/g-4.jpg'
import G5 from './../images/g-5.jpg'
import G6 from './../images/g-6.jpg'

const Gallery = () => {
  return (
    <section class="gallery" id="gallery">
      <h1 class="heading">
        {" "}
        unsere <span>Galerie</span>{" "}
      </h1>

      <div class="box-container">
        <div class="box">
          <img src={G1} alt="" />
          <div class="content">
            <h3>project 01</h3>
            <span>01/01/2023</span>
          </div>
        </div>

        <div class="box">
          <img src={G2} alt="" />
          <div class="content">
            <h3>project 02</h3>
            <span>01/01/2023</span>
          </div>
        </div>

        <div class="box">
          <img src={G3} alt="" />
          <div class="content">
            <h3>project 03</h3>
            <span>01/01/2023</span>
          </div>
        </div>

        <div class="box">
          <img src={G4} alt="" />
          <div class="content">
            <h3>project 04</h3>
            <span>01/01/2023</span>
          </div>
        </div>

        <div class="box">
          <img src={G5} alt="" />
          <div class="content">
            <h3>project 05</h3>
            <span>01/01/2023</span>
          </div>
        </div>

        <div class="box">
          <img src={G6} alt="" />
          <div class="content">
            <h3>project 06</h3>
            <span>01/01/2023</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
