import React from "react";

import P1 from './../images/pic-1.png'
import P2 from './../images/pic-2.png'
import P3 from './../images/pic-3.png'

const Review = () => {
  return (
    <section class="review" id="review">
      <h1 class="heading">
        {" "}
        Kunden <span>Bewertung</span>{" "}
      </h1>

      <div class="box-container">
        <div class="box">
          <div class="user">
            <img src={P1} alt="" />
            <div class="info">
              <h3>john deo</h3>
              <div class="stars">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star-half-alt"></i>
                <span>(4.5)</span>
              </div>
            </div>
            <i class="fas fa-quote-right"></i>
          </div>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nemo ut
            veniam modi vitae ad explicabo, provident iusto, officiis
            voluptatibus debitis possimus sequi blanditiis mollitia excepturi
            repellendus omnis non doloremque expedita?
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius
            numquam assumenda perferendis soluta sunt, et ducimus deleniti
            natus. Cupiditate, repellat.
          </p>
        </div>

        <div class="box">
          <div class="user">
            <img src={P2} alt="" />
            <div class="info">
              <h3>john deo</h3>
              <div class="stars">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star-half-alt"></i>
                <span>(4.5)</span>
              </div>
            </div>
            <i class="fas fa-quote-right"></i>
          </div>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nemo ut
            veniam modi vitae ad explicabo, provident iusto, officiis
            voluptatibus debitis possimus sequi blanditiis mollitia excepturi
            repellendus omnis non doloremque expedita?
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius
            numquam assumenda perferendis soluta sunt, et ducimus deleniti
            natus. Cupiditate, repellat.
          </p>
        </div>

        <div class="box">
          <div class="user">
            <img src={P3} alt="" />
            <div class="info">
              <h3>john deo</h3>
              <div class="stars">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star-half-alt"></i>
                <span>(4.5)</span>
              </div>
            </div>
            <i class="fas fa-quote-right"></i>
          </div>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nemo ut
            veniam modi vitae ad explicabo, provident iusto, officiis
            voluptatibus debitis possimus sequi blanditiis mollitia excepturi
            repellendus omnis non doloremque expedita?
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius
            numquam assumenda perferendis soluta sunt, et ducimus deleniti
            natus. Cupiditate, repellat.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Review;
