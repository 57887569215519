import React from "react";

const Pricing = () => {
  return (
    <section class="pricing" id="pricing">
      <h1 class="heading">
        {" "}
        unsere <span>preise</span>{" "}
      </h1>

      <div class="box-contanier">
        <div class="box">
          <span class="fas fa-paper-plane"></span>
          <h3>basic plan</h3>
          <div class="price">
            {" "}
            <i>€</i>10<i>/mtl</i>{" "}
          </div>
          <ul>
            <li>
              {" "}
              <i class="fas fa-check"></i> 5 projekte{" "}
            </li>
            <li>
              {" "}
              <i class="fas fa-check"></i> instandhaltung{" "}
            </li>
            <li>
              {" "}
              <i class="fas fa-check"></i> 5GB speicher{" "}
            </li>
            <li>
              {" "}
              <i class="fas fa-check"></i> 1 domain{" "}
            </li>
            <li>
              {" "}
              <i class="fas fa-check"></i> 24/7 service{" "}
            </li>
          </ul>
          <a href="#" class="btn">
            buchen
          </a>
        </div>

        <div class="box">
          <span class="fas fa-plane"></span>
          <h3>standard plan</h3>
          <div class="price">
            {" "}
            <i>€</i>30<i>/mtl</i>{" "}
          </div>
          <ul>
            <li>
              {" "}
              <i class="fas fa-check"></i> 15 projekte{" "}
            </li>
            <li>
              {" "}
              <i class="fas fa-check"></i> instandhaltung{" "}
            </li>
            <li>
              {" "}
              <i class="fas fa-check"></i> 15GB speicher{" "}
            </li>
            <li>
              {" "}
              <i class="fas fa-check"></i> 15 domain{" "}
            </li>
            <li>
              {" "}
              <i class="fas fa-check"></i> 24/7 service{" "}
            </li>
          </ul>
          <a href="#" class="btn">
            buchen
          </a>
        </div>

        <div class="box">
          <span class="fas fa-rocket"></span>
          <h3>premium plan</h3>
          <div class="price">
            {" "}
            <i>€</i>50<i>/mtl</i>{" "}
          </div>
          <ul>
            <li>
              {" "}
              <i class="fas fa-check"></i> 25 projekte{" "}
            </li>
            <li>
              {" "}
              <i class="fas fa-check"></i> instandhaltung{" "}
            </li>
            <li>
              {" "}
              <i class="fas fa-check"></i> 25GB speicher{" "}
            </li>
            <li>
              {" "}
              <i class="fas fa-check"></i> 25 domain{" "}
            </li>
            <li>
              {" "}
              <i class="fas fa-check"></i> 24/7 service{" "}
            </li>
          </ul>
          <a href="#" class="btn">
            buchen
          </a>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
