import React, { useEffect } from "react";

const Navbar = () => {
  useEffect(() => {
    const menuBtn = document.querySelector("#menu-btn");
    const searchBtn = document.querySelector("#search-btn");
    const colorBtn = document.querySelector("#color-btn");
    const themeBtn = document.querySelector("#theme-btn");

    const navbar = document.querySelector(".navbar");
    const searchForm = document.querySelector(".search-form");
    const colorsPalette = document.querySelector(".colors-palette");

    const handleMenuBtnClick = () => {
      navbar.classList.toggle("active");
      searchForm.classList.remove("active");
      colorsPalette.classList.remove("active");
    };

    const handleSearchBtnClick = () => {
      searchForm.classList.toggle("active");
      navbar.classList.remove("active");
      colorsPalette.classList.remove("active");
    };

    const handleColorBtnClick = () => {
      colorsPalette.classList.toggle("active");
      navbar.classList.remove("active");
      searchForm.classList.remove("active");
    };

    const handleScroll = () => {
      navbar.classList.remove("active");
      searchForm.classList.remove("active");
      colorsPalette.classList.remove("active");
    };

    const handleColorClick = (event) => {
      const color = event.target.style.background;
      document.querySelector(":root").style.setProperty("--main-color", color);
    };

    const handleThemeBtnClick = () => {
      themeBtn.classList.toggle("fa-sun");
      if (themeBtn.classList.contains("fa-sun")) {
        document.body.classList.add("active");
      } else {
        document.body.classList.remove("active");
      }
    };

    menuBtn.addEventListener("click", handleMenuBtnClick);
    searchBtn.addEventListener("click", handleSearchBtnClick);
    colorBtn.addEventListener("click", handleColorBtnClick);
    window.addEventListener("scroll", handleScroll);
    colorsPalette
      .querySelectorAll(".colors-palette .color")
      .forEach((btn) => {
        btn.addEventListener("click", handleColorClick);
      });
    themeBtn.addEventListener("click", handleThemeBtnClick);

    return () => {
      menuBtn.removeEventListener("click", handleMenuBtnClick);
      searchBtn.removeEventListener("click", handleSearchBtnClick);
      colorBtn.removeEventListener("click", handleColorBtnClick);
      window.removeEventListener("scroll", handleScroll);
      colorsPalette
        .querySelectorAll(".colors-palette .color")
        .forEach((btn) => {
          btn.removeEventListener("click", handleColorClick);
        });
      themeBtn.removeEventListener("click", handleThemeBtnClick);
    };
  }, []);

  return (
    <div className="header">
      <a href="#" class="logo">
        Show3
      </a>

      <div class="icons">
        <div class="fas fa-moon" id="theme-btn"></div>
        <div class="fas fa-palette" id="color-btn"></div>
        <div class="fas fa-search" id="search-btn"></div>
        <div class="fas fa-bars" id="menu-btn"></div>
      </div>

      <nav class="navbar">
        <a href="#home">home</a>
        <a href="#services">service</a>
        <a href="#gallery">galerie</a>
        <a href="#pricing">preise</a>
        <a href="#review">bewertungen</a>
        <a href="#contact">kontakt</a>
      </nav>

      <form action="" class="search-form">
        <input
          type="search"
          name=""
          placeholder="search here..."
          id="search-box"
        />
        <label for="search-box" class="fas fa-search"></label>
      </form>

      <div class="colors-palette">
        <h3>choose color</h3>
        <div class="colors">
          <div className="color" style={{ background: "#2980b9" }}></div>
          <div className="color" style={{ background: "#27ae60" }}></div>
          <div className="color" style={{ background: "#e74c3c" }}></div>
          <div className="color" style={{ background: "#8e44ad" }}></div>
          <div className="color" style={{ background: "#B33771" }}></div>
          <div className="color" style={{ background: "#0fb9b1" }}></div>
          <div className="color" style={{ background: "#ff9f1a" }}></div>
          <div className="color" style={{ background: "#e84393" }}></div>
          <div className="color" style={{ background: "#17c0eb" }}></div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
